// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-data-calculated-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/data/calculated.mdx" /* webpackChunkName: "component---src-pages-data-calculated-mdx" */),
  "component---src-pages-data-cheaply-calculated-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/data/cheaply-calculated.mdx" /* webpackChunkName: "component---src-pages-data-cheaply-calculated-mdx" */),
  "component---src-pages-data-index-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/data/index.mdx" /* webpackChunkName: "component---src-pages-data-index-mdx" */),
  "component---src-pages-data-no-re-render-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/data/no-re-render.mdx" /* webpackChunkName: "component---src-pages-data-no-re-render-mdx" */),
  "component---src-pages-data-re-render-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/data/re-render.mdx" /* webpackChunkName: "component---src-pages-data-re-render-mdx" */),
  "component---src-pages-function-callback-or-not-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/function/callback-or-not.mdx" /* webpackChunkName: "component---src-pages-function-callback-or-not-mdx" */),
  "component---src-pages-function-index-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/function/index.mdx" /* webpackChunkName: "component---src-pages-function-index-mdx" */),
  "component---src-pages-hooks-use-callback-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useCallback.mdx" /* webpackChunkName: "component---src-pages-hooks-use-callback-mdx" */),
  "component---src-pages-hooks-use-context-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useContext.mdx" /* webpackChunkName: "component---src-pages-hooks-use-context-mdx" */),
  "component---src-pages-hooks-use-memo-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useMemo.mdx" /* webpackChunkName: "component---src-pages-hooks-use-memo-mdx" */),
  "component---src-pages-hooks-use-effect-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useEffect.mdx" /* webpackChunkName: "component---src-pages-hooks-use-effect-mdx" */),
  "component---src-pages-hooks-use-reducer-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useReducer.mdx" /* webpackChunkName: "component---src-pages-hooks-use-reducer-mdx" */),
  "component---src-pages-hooks-use-ref-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useRef.mdx" /* webpackChunkName: "component---src-pages-hooks-use-ref-mdx" */),
  "component---src-pages-hooks-use-state-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/hooks/useState.mdx" /* webpackChunkName: "component---src-pages-hooks-use-state-mdx" */),
  "component---src-pages-index-mdx": () => import("/Users/george/src/gsong/whichhook.dev/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/george/src/gsong/whichhook.dev/.cache/data.json")

